/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { imsLoader, crossIcon } from "../../assets";
import './GenAIAnsweringPreview.css'
import { LocationOrigin } from '../../utils/utilityServices';
import { withRouter, Link } from 'react-router-dom';
import axios from "axios";
import GenAIPreviewSidebar from '../GenAIAnswering2.0/GenAIPreviewSidebar';
import { GenAIAnsweringContext } from '../GenAIAnswering2.0/GenAIAnsweringContextProvider';

class GenAIAnsweringWindowPreview extends Component {
    static contextType = GenAIAnsweringContext
    constructor(props) {
        super(props);
        this.state = {
            previewURL: '',
            apiURL: '',
            selectedSource: null,
            selectedPageNumber: [],
            isProcessing: true,
            PageNumber: 1,
            isMultiFiles: null,
            attachedFiles: [],
            DisplayString: [],
            isChangePage:false,
        }

    }
    componentDidMount = async () => {
        const queryParams = new URLSearchParams(this.props.location.search);
        let eventid = this.props.match.params.id;
        let _id = queryParams.get("docid");
        let prviewObject = window[`${eventid}-${_id}`];
        if (prviewObject) {
            this.setState({
                selectedSource: prviewObject.selectedSource,
                selectedPageNumber: prviewObject.selectedPageNumber,
                isMultiFiles: prviewObject.isMultiFiles,
                attachedFiles: prviewObject.attachedFiles,
                PageNumber: prviewObject.selectedPageNumber[0],
                DisplayString: prviewObject.DisplayString,
                isRecommendation: prviewObject.isRecommendation,
            })
            let { isMultiFiles, selectedSource, selectedPageNumber } = prviewObject;
            let apiURL = "";
            if (isMultiFiles) {
                let { attachedFiles } = prviewObject;
                let filtersFile = attachedFiles.filter((singleFile => {
                    if (selectedSource.document_guid && singleFile.guid) {
                        return singleFile.guid === selectedSource.document_guid;
                    } else {
                        return singleFile.name === selectedSource.document_title;
                    }
                }))
                if (filtersFile.length > 0) {
                    let singleFile = filtersFile[0];
                    var url = URL.createObjectURL(singleFile);
                    this.setState({ previewURL: url }, async () => {
                        this.setState({ isProcessing: false })
                    })
                } else {
                    this.setState({ previewURL: "" }, async () => {
                        this.setState({ isProcessing: false })
                    })
                }
            } else {
                if (decodeURIComponent(selectedSource.download_url).includes("csID=29")) {
                    var href = new URL(`https://ims.jnj.com${selectedSource.download_url}`);
                    href.searchParams.set('connID', '7');
                    href.searchParams.set('csID', '50');
                    href.searchParams.set('fileType', 'pdf');
                    apiURL = href.toString();
                } else {
                    apiURL = `https://ims.jnj.com${selectedSource.download_url}`
                }
                if (selectedSource.enclosureDocID && selectedSource.enclosureDocID.trim()) {
                    apiURL += `&enclosureID=${selectedSource.enclosureDocID}`
                }
                this.setState({ apiURL: apiURL }, async () => {
                    await this.fetchDocument();
                })
            }
        } else {
            this.setState({ previewURL: "" }, async () => {
                this.setState({ isProcessing: false })
            })
        }

    }


    fetchDocument = async () => {
        const { apiURL } = this.state;
        await axios.get(
            apiURL,
            {
                responseType: "blob",
            }
        ).then((response) => {
            const data = new Blob([response.data], { type: 'application/pdf' });
            var url = window.URL.createObjectURL(data)
            this.setState({ previewURL: url }, async () => {
                this.setState({ isProcessing: false })
            })
        }).catch((error) => {
            console.log(error);
            this.setState({ previewURL: "" }, async () => {
                this.setState({ isProcessing: false })
            })
        });
    }
    handleclickCitation = (pageNumber) => {
        let {isChangePage} = this.state;
        this.setState({ PageNumber: pageNumber , isChangePage: !isChangePage})
    }

    render() {

        let { isProcessing, PageNumber, previewURL, isMultiFiles, selectedSource, DisplayString, isRecommendation,isChangePage } = this.state;
        return (<div className="gen-ai-document-window-preview-wrapper">
            {isProcessing ? <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <img src={imsLoader} alt="Loading Filters" style={{ width: "10%", marginTop: "200px" }} />
            </div> : <>
                {previewURL ? <div className="gen-ai-document-window-preview-panel">
                     <object key={`"gen-ai-document-window-preview-panel-${PageNumber}-${isChangePage}`} data={`${previewURL}#page=${PageNumber}`} className={this.context.isCollapsedWindowPreviewInfo ? "gen-ai-document-window-preview-collapsed" : "gen-ai-document-window-preview-expand"} height="100%">
                        <p>Unable to display PDF file.</p>
                    </object>
                    <GenAIPreviewSidebar selectedSource={selectedSource} isMultiFiles={isMultiFiles}
                        DisplayString={DisplayString} handleclickCitation={this.handleclickCitation} isRecommendation={isRecommendation} />
                </div> : <div className="gen-ai-document-preview-error-panel">
                    <p>Unable to display PDF file.</p>
                </div>}
            </>
            }

        </div>)
    }
}
export default withRouter(GenAIAnsweringWindowPreview);
